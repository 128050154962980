import React from 'react'
import Layout from 'layouts/en'
import styled from 'styled-components'
import design from 'design'
import { Title, Text, Row, Div, Box, Button } from 'components'
import { TryOrSchedule } from 'containers/en'

export default () => {
  return (
    <Layout p={5}>
      <div id="mc_embed_signup">
        <form action="https://stairlin.us14.list-manage.com/subscribe/post?u=d61bf0db69f16c103c6148607&amp;id=58d0170f73" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
          <div id="mc_embed_signup_scroll">
            <h2>Subscribe</h2>
            <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
            <div class="mc-field-group">
              <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
              </label>
              <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL"/>
            </div>
            <div class="mc-field-group">
              <label for="mce-FNAME">First Name  <span class="asterisk">*</span>
              </label>
              <input type="text" value="" name="FNAME" class="required" id="mce-FNAME"/>
            </div>
            <div class="mc-field-group">
              <label for="mce-LNAME">Last Name  <span class="asterisk">*</span>
              </label>
              <input type="text" value="" name="LNAME" class="required" id="mce-LNAME"/>
            </div>
            <div class="mc-field-group size1of2">
              <label for="mce-MMERGE4">Phone  <span class="asterisk">*</span>
              </label>
              <input type="text" name="MMERGE4" class="required" value="" id="mce-MMERGE4"/>
            </div>
            <div id="mce-responses" class="clear">
              <div class="response" id="mce-error-response" style={{display:`none`}}></div>
              <div class="response" id="mce-success-response" style={{display:`none`}}></div>
            </div>
            <div style={{position: `absolute`, left: `-5000px`}} aria-hidden="true"><input type="text" name="b_d61bf0db69f16c103c6148607_32e3bf2da7" tabindex="-1" value=""/></div>
            <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"/></div>
          </div>
        </form>
      </div>
    </Layout>
  )
}
